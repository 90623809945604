import {connect} from "react-redux";
import ProgramContestResult from "../components/program/ProgramContestResult";

const slammersByCompetition = (state, competition) => {
    competition = state.competitions[competition];
    if(competition){
        let hasSlammer = false;
        let group = null;
        let slammers = competition.groups.map(id => {
            if(group===null){
                group = id;
            }
            let participants = state.groups[id].participants;
            if(participants.length>0) {
                let participantsById = state.participantsById[participants[0]];
                let altGroup = id !== group && (competition.drawn || participantsById && participantsById.scores && participantsById.scores.length>0);
                return participants.map(id => {
                    let participant = state.participantsById[id];
                    if (participant && participant.name) {
                        hasSlammer = true;
                        return {
                            id: participant.id,
                            name: participant.name,
                            scores: participant.scores,
                            altGroup,
                        };
                    }
                    return {
                        id: participant.id,
                        //                    altGroup,
                    }
                });
            }
        });
        if(hasSlammer) {
            return [...slammers[0], ...slammers[1]];
        }
    }
    return [];
};

const mapStateToProps = (state, ownProps) => {
    return {
        slammers: slammersByCompetition(state, ownProps.competition)
    }
};

export default connect(
    mapStateToProps,
)(ProgramContestResult);
