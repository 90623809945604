import React from 'react'
import { graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../../containers/layout";
import SubPage from "../../containers/subpage";
import FetchedProgramContestResults from "../../containers/FetchedProgrammContestResults";

const ResultatePage = ({data}) => {
    let gatsbyImg=null;

    if(data.background && data.background.childImageSharp){
        gatsbyImg = (
            <Img
                fluid={data.background.childImageSharp.fluid}
            />
        );
    }

    return (
        <Layout pageTitle={"Resultate"}>
            <SubPage
                back={'/programm/'}
                image={gatsbyImg}
                header={(
                    <>
                    <h2>Resultate</h2>
                    </>
                )}
            >
                <div className={'container'}>
                    {
                        data.events.edges.map(({event}) => {
                            let taxonomy = event.custom_fields.taxonomies.reduce((acc, current) =>
                                current.slug==='slam-2018'? acc: current
                            );
                            console.log("taxonomy", taxonomy);
                            if(taxonomy.slug==='rahmenprogram'){
                                return null;
                            }

                            console.log("event", event.title);
                            return (
                                <>
                                <h3>{event.title}</h3>
                                <FetchedProgramContestResults competition={event.title} />
                                </>
                            );

                        })
                    }
                </div>
            </SubPage>
        </Layout>
    );
};

export default ResultatePage;


export const query = graphql`
    query ResultatePageQuery {
        background: file(relativePath: {eq: "carousel/Web-Karussell_FotoLisa.png"}){
            childImageSharp{
                fluid {
                    ...GatsbyImageSharpFluid_tracedSVG
                }
            }
        }
        events: allWordpressWpEvents(
            filter: {content: {ne: ""}},
            sort: {fields: [custom_fields___wpcf_veranstaltungsdatum, custom_fields___wpcf_tueroeffnung, title]}
        ){
            edges{
                event: node{
                    title
                    custom_fields{
                        start: wpcf_veranstaltungsdatum
                        end: wpcf_enddatum
                        subtitle: wpcf_untertitel
                        tueroeffnung: wpcf_tueroeffnung
                        
                        taxonomies{
                            slug
                        }
                    }
                }
            }
        }
    }
`;