import React from 'react';
import injectSheet from 'react-jss';
import ProgramContestResultContent from "./ProgramContestResultContent";

const styles = theme => {
    let color = theme.palette.primary.dark;
    let altColor = theme.palette.secondary.dark;
    return {
        root: {
            display: 'flex',
            flexDirection: 'column',
            marginTop: '2rem',
        },
        slammer: {
            display: 'flex',
            border: '2px solid '+ color,
            color: color,
            marginBottom: '.5rem',
            fontSize: '1.25rem',
            fontWeight: 400,
            [theme.breakpoints.down('xs')]: {
                    flexDirection: 'column',
            },
            '&:hover .ignored': {
                transform: 'rotate(45deg)',
            },
            '&.altGroup':{
                border: '2px solid '+ altColor,
                color: altColor,
                '& $name':{
                    background: altColor,
                }
            }
        },
        name: {
            width: '40%',
            minWidth: '40%',
            color: theme.palette.background,
            background: color,
            padding: '.5rem',
            [theme.breakpoints.down('xs')]: {
                width: 'auto',
            },
        },
        scores: {
            flex: '1 1 auto',
            display: 'flex',
            minHeight: '1.5em',
        },
    };
};

const ProgramContestResult = ({slammers, classes}) => {
    return (
        <section className={classes.root}>
            {
                slammers.map((slammer, index) => {
                    if(!slammer)
                        slammer = {id: index, scores: []};
                    const {id, name, scores, altGroup} = slammer;
                    let scoresEl;
                    if(scores && scores.length>0){
                        let value = 0;
                        scores.forEach( score => {
                            if(!score.ignored){
                                value += score.value;
                            }
                        });
                        value = Math.round(value*10)/10;
                        scoresEl = (
                            <>
                            {scores.map((score, index) => (<ProgramContestResultContent key={index} altGroup={altGroup} {...score}/>))}
                            <ProgramContestResultContent
                                value={value}
                                total={true}
                                altGroup={altGroup}
                            />
                            </>
                        )
                    }
                    return (
                        <section className={classes.slammer + (altGroup? ' altGroup': '')} key={id}>
                            <div className={classes.name}>{name}</div>
                            <div className={classes.scores}>
                                {scoresEl}
                            </div>
                        </section>
                    )
                })
            }
        </section>
    );
};


export default injectSheet(styles)(ProgramContestResult);