import React from 'react';
import injectSheet from 'react-jss';

const styles = theme => {
    let color = theme.palette.primary.dark;
    return {
        root: {
            flex: '1 1 auto',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderLeft: '1px solid '+color,
            borderRight: '1px solid '+color,
            '&.ignored': {
                color: theme.palette.primary.light,
                background: theme.palette.backgroundSecondary,
                textDecoration: 'line-through',
                textDecorationColor: color,
                transition: 'transform .5s ease-in',
                transformOrigin: 'bottom left',
            },
            '&.total': {
                background: theme.palette.primary.dark,
                color: theme.palette.background,
                fontWeight: 600,
            },
            '&.altGroup': {
                borderColor: theme.palette.secondary.dark,
                '&.total': {
                    background: theme.palette.secondary.dark,
                    color: theme.palette.background,
                },
            },
        },
    };
};

const ProgramContestResultContent = ({value, ignored=false, total=false, altGroup=false, classes}) => {
    if(value % 1 === 0){
        value += '.0';
    }
    return (
        <span className={classes.root + (total? ' total': '') + (ignored? ' ignored': '') + (altGroup? ' altGroup': '')}>{value}</span>
    );
};

export default injectSheet(styles)(ProgramContestResultContent);